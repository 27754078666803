<header class="header">
	<div class="header__title">
		<a class="header-side-button site-icon-button d-none d-md-inline"
			[routerLink]="routes.app.home"
			routerLinkActive="active">
			<img class="header__title-icon header__icon" [src]="images.siteMainImage" alt="SC"/>
		</a>
		<h1 class="header__title-text theme-text-shadow">Sébastien CAYET</h1>
	</div>
	<ul class="list-unstyled p-0 m-0 d-none d-md-flex">
		<li class="px-2">
			<a class="theme-text-shadow"
				[routerLink]="routes.app.home"
            	routerLinkActive="active">
				{{ 'Accueil' | translate:'home' }}
			</a>
		</li>
		<li class="px-2">
			<a class="theme-text-shadow"
				[routerLink]="routes.app.blog"
            	routerLinkActive="active">
				{{ 'Blog' | translate:'blog' }}
			</a>
		</li>
	</ul>
    <ul class="header__side-menu d-none d-md-flex">
        <li>
            <button class="header__language inline p-0" data-toggle="dropdown">
                <img class="header__icon"
                    [src]="getCurrentLocale().image"
                    [alt]="getCurrentLocale().id.toUpperCase()"/>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownLanguageButton">
				@for (locale of getAvailableLocales(); track $index) {
					<button class="dropdown-item" (click)="setLocale(locale.id)">{{locale.name}}</button>
				}
            </div>
        </li>
        <li class="theme-text-shadow mt-1">
            <a [href]="routes.external.twitter" target="_blank" aria-label="Visit X">
                <span class="icon-x-twitter"></span>
            </a>
        </li>
        <li class="theme-text-shadow mt-1">
            <a [href]="routes.external.linkedIn" target="_blank" aria-label="Visit LinkedIn">
                <span class="icon-linkedin-brands"></span>
            </a>
        </li>
        <li class="theme-text-shadow mt-1">
            <a [href]="routes.external.medium" target="_blank" aria-label="Visit Medium">
                <span class="icon-medium"></span>
            </a>
        </li>
        <li class="theme-text-shadow mt-1">
            <a [href]="routes.external.gitHub" target="_blank" aria-label="Visit GitHub">
                <span class="icon-github-brands"></span>
            </a>
        </li>
        <li class="theme-text-shadow mt-1">
            <a [href]="routes.external.itch" target="_blank" aria-label="Visit Itch.io">
                <span class="icon-itch-io"></span>
            </a>
        </li>
    </ul>
	<nav class="dropdown d-md-none header__dropdown-menu">
		<button type="button"
			class="btn btn-light rounded-circle p-2 header__dropdown-menu__toggle-button theme-text-shadow"
			style="width: 2.5rem; height: 2.5rem;"
			id="dropdownSmallscreenMenuButton"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false">
			<span class="icon-bars"></span>
		</button>
		<div class="dropdown-menu" aria-labelledby="dropdownSmallscreenMenuButton">
			<a class="dropdown-item header__dropdown-item" [routerLink]="routes.app.home">
				{{ 'Accueil' | translate:'home' }}
			</a>
			<a class="dropdown-item header__dropdown-item" [routerLink]="routes.app.blog">
				{{ 'Blog' | translate:'blog' }}
			</a>
			<div class="dropdown-divider"></div>
			<ul class="header__dropdown-menu list-unstyled px-4 m-0 d-flex justify-content-between">
				<li class="theme-text-shadow mt-1">
					<a [href]="routes.external.twitter" target="_blank" aria-label="Visit X">
						<span class="icon-x-twitter"></span>
					</a>
				</li>
				<li class="theme-text-shadow mt-1">
					<a [href]="routes.external.linkedIn" target="_blank" aria-label="Visit LinkedIn">
						<span class="icon-linkedin-brands"></span>
					</a>
				</li>
				<li class="theme-text-shadow mt-1">
					<a [href]="routes.external.medium" target="_blank" aria-label="Visit Medium">
						<span class="icon-medium"></span>
					</a>
				</li>
				<li class="theme-text-shadow mt-1">
					<a [href]="routes.external.gitHub" target="_blank" aria-label="Visit GitHub">
						<span class="icon-github-brands"></span>
					</a>
				</li>
				<li class="theme-text-shadow mt-1">
					<a [href]="routes.external.itch" target="_blank" aria-label="Visit Itch.io">
						<span class="icon-itch-io"></span>
					</a>
				</li>
			</ul>
			<div class="dropdown-divider"></div>
			<div class="">
				@for (locale of getAvailableLocales(); track $index) {
					<button class="dropdown-item" (click)="setLocale(locale.id)">
						<img class="header__icon mr-2"
							[src]="locale.image"
							[alt]="locale.id.toUpperCase()"/>
						<span>{{locale.name}}</span>
					</button>
				}
			</div>
		</div>
	</nav>
</header>

